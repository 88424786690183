import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPromotion from "@/vue/domain/promotion/df-promotion";

const DfCardFeauturedComponent = () => Utils.externalComponent2("df-card-feautured");

@Component({
  components: {
    DfCardFeautured: DfCardFeauturedComponent,
  },
})
export default class DfSectionFeauturedComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: "" })
  position!: string;
  @Prop({ type: Object, default: null })
  promotion: DfPromotion;

  get feauturedes(): Array<DfContent> {
    return this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "FEA") || [];
  }

  get feauturedesByPosition(): Array<DfContent> {
    const feauturedes: Array<DfContent> = this.feauturedes.filter((feautured: DfContent) => {
      const value: any = Utils.getPropertyValue(feautured, Utils.PROPERTY_POSITION, "LIST", {});
      return value.key === this.position;
    });
    // TODO: Eliminare a inizio 2023
    const feauturedesFake: Array<DfContent> = this.feauturedes.filter((feautured: DfContent) => {
      const value: string = Utils.getPropertyValue(feautured, Utils.PROPERTY_POSITION, "STRING");
      return value === this.position;
    });

    return feauturedes.length ? feauturedes : feauturedesFake;
  }

  @Watch("feauturedes")
  private changeVisibility() {
    this.$emit("setVisibility", this.feauturedesByPosition.length > 0);
  }
}
